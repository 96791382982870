require('dotenv').config();

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag'
import './assets/stylesheets/application.scss';

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
      id: 'G-4GYNE9X42T',
  }
}, router)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
